import React from "react";

import { Route, Redirect } from "react-router-dom";


class PrivateRoute extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            isAuthenticated: false,
        };

        this.signIn = this.signIn.bind(this);
        this.signOut = this.signOut.bind(this);
    }

    componentWillMount() {
        this.signIn();
    }

    componentWillUpdate() {
        this.signIn();
    }



    signIn() {
        let accessToken = localStorage.getItem("accessToken");
        if (accessToken) {
            if (!this.state.isAuthenticated) {
                this.setState({ isAuthenticated: true })
            }

        }
        else {
            this.signOut();
        }

    }

    signOut() {
        if (this.state.isAuthenticated) {
            this.setState({ isAuthenticated: false })
            localStorage.clear();
        }

    }

    render() {
        if (this.state.isAuthenticated) {
            return (<Route
                {...this.props}
            />);
        }
        else {
            return (<Redirect
                to={{
                    pathname: "/sign-in",
                    state: { from: this.props.location }
                }}
            />);
        }
    }
}

export default PrivateRoute;