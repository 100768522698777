import React from 'react';
import './App.scss';
import { BrowserRouter as Router, Switch, Route, Redirect } from "react-router-dom";
import * as constants from "./constants";
import { ToastContainer } from 'react-toastify';
import PrivateRoute from "./privateRoute";
import 'react-toastify/dist/ReactToastify.css';
import axios from 'axios';

const loading = (
  <div className="loader">
    <div className="spinner-border text-primary" role="status">
    </div>
    <div className="text-primary"> Please Wait...</div>
  </div>
)

const Login = React.lazy(() => import('./Components/Login/Login'));
const Registration = React.lazy(() => import('./Components/Registration/Registration'));
const ForgotPassWord = React.lazy(() => import('./Components/ForgotPassWord/ForgotPassWord'));
const ResetPassword = React.lazy(() => import('./Components/ResetPassword/Resetpassword'));
const TableMigration = React.lazy(() => import('./Components/TableMigration/TableMigration'));
const ObjectMigration = React.lazy(() => import('./Components/ObjectMigration/ObjectMigration'))
const ViewMigration = React.lazy(() => import('./Components/ViewMigration/ViewMigration'));
const LandingPage = React.lazy(() => import('./Components/LandingPage/LandingPage'));

class App extends React.Component {
  DataMigration_Hana = React.lazy(() => import('./Components/DataMigration/DataMigration'));
  DataMigration_BW = React.lazy(() => import('./Components/DataMigration/DataMigration_bw'));

  constructor(props) {
    super(props);
    /** clears console */
    console.log(process.env.NODE_ENV);
    if(process.env.NODE_ENV == "production")
    {
      
      console.log = function() {}
      console.error = function() {}
      console.warn = function() {}
    }
    
    this.state = {
      inbound_connections: [],
      outbound_connections: [],
      choice: "",
      blocking: false,
      blockingMessage: constants.BlockUI_Messages.Default,
      selectedSystem: {
        Inbound: "SAP HANA",
        Outbound: "Snowflake",
      },
      logData: []
    };
    this.setInboundConnections = this.setInboundConnections.bind(this);
    this.setOutboundConnections = this.setOutboundConnections.bind(this);
    this.setChoice = this.setChoice.bind(this);
    this.setSelectedSystem = this.setSelectedSystem.bind(this);
    this.toggleBlocking = this.toggleBlocking.bind(this);
    this.setBlockUIMessage = this.setBlockUIMessage.bind(this);
    this.log = this.log.bind(this);
    this.logout = this.logout.bind(this);
  }

  logout()
  {
    this.setState({
      inbound_connections: [],
      outbound_connections: [],
      choice: "",
      blocking: false,
      blockingMessage: constants.BlockUI_Messages.Default,
      selectedSystem: {
        Inbound: "SAP HANA",
        Outbound: "Snowflake",
      },
      logData: []
    }, () => {
      localStorage.clear();
      window.location.href = "/sign-in"
    })
  }

  log(logData) {
    let log = this.state.logData;
    log.unshift(logData);
    this.setState({ logData: [...log] });
  }
  setBlockUIMessage(message) {
    this.setState({
      blockingMessage: message
    }, () => {
    })
  }
  toggleBlocking(e) {
    if (typeof e == "boolean") {
      this.setState({ blocking: e });
    }
    else {
      this.setState({ blocking: !this.state.blocking });
    }

  }

  setChoice(choice) {
    this.setState({ choice: choice });
  }

  

  componentDidMount() {
    if(localStorage.getItem("accessToken"))
    {
      axios.defaults.headers.post['x-access-token'] = localStorage.getItem("accessToken")
    }
  }

  componentDidUpdate()
  {
    if(localStorage.getItem("accessToken"))
    {
      axios.defaults.headers.post['x-access-token'] = localStorage.getItem("accessToken")
    }
    
  }

  setInboundConnections(connectionName) {
    if (window.location.pathname === "/landing-page") {
    }
    let inbound_connections = this.state.inbound_connections;
    if (this.state.inbound_connections.indexOf(connectionName)) {
      inbound_connections.push(connectionName);
      this.setState({ inbound_connections: inbound_connections })
    }
  }

  setOutboundConnections(connectionName) {
    let outbound_connections = this.state.outbound_connections;
    if (this.state.outbound_connections.indexOf(connectionName) < 0) {
      outbound_connections.push(connectionName);
      this.setState({ outbound_connections: outbound_connections })
    }
  }

  deleteConnection(caseParam, connectionName)
  {
    if(caseParam == "inbound")
    {
        let inbound_connections = this.state.inbound_connections;
        console.log(inbound_connections, "conn")
        let index = inbound_connections.findIndex(e => e.connection_name == connectionName);
        if(index >= 0)
        {
          inbound_connections.splice(index, 1);
          this.setState({ inbound_connections : inbound_connections});
        }

    }
    else{
      let outbound_connections = this.state.outbound_connections;
        let index = outbound_connections.findIndex(e => e.connection_name == connectionName);
        if(index >= 0)
        {
          outbound_connections.splice(index, 1);
          this.setState({ outbound_connections : outbound_connections});
        }
    }
  }

  setSelectedSystem(system) {
    this.setState({ selectedSystem: system });
  }


  render() {

    const DataMigration = (this.state.selectedSystem.Inbound == "SAP BW"
      // && this.state.selectedSystem.Outbound == "SAP BW"
    ) ? this.DataMigration_BW : this.DataMigration_Hana;
    return (
      <Router>
        <div className="App">
          <React.Suspense fallback={loading}>
            <ToastContainer
              position="top-right"
              autoClose={5000}
              hideProgressBar={false}
              newestOnTop
              closeOnClick
              rtl={false}
              pauseOnFocusLoss
              draggable
              pauseOnHover />
            <Switch>
              <Route exact path='/' render={() => <Redirect from="/" to="/sign-in" />} />
              <Route path="/sign-in" render={props => <Login {...props}
                blocking={this.state.blocking}
                toggleBlocking={(e) => this.toggleBlocking(e)}
                blockingMessage={this.state.blockingMessage}
                setBlockUIMessage={(message) => this.setBlockUIMessage(message)}
                logout = {() => {this.logout()}}
              />} />

              <Route path="/sign-up" render={props => <Registration {...props}
                blocking={this.state.blocking}
                toggleBlocking={(e) => this.toggleBlocking(e)}
                blockingMessage={this.state.blockingMessage}
                setBlockUIMessage={(message) => this.setBlockUIMessage(message)}
                logout = {() => {this.logout()}}
              />} />

              <Route path="/forgot-password" render={props => <ForgotPassWord {...props}
                blocking={this.state.blocking}
                toggleBlocking={(e) => this.toggleBlocking(e)}
                blockingMessage={this.state.blockingMessage}
                setBlockUIMessage={(message) => this.setBlockUIMessage(message)}
                logout = {() => {this.logout()}}
              />} />

              <Route path="/reset-password" render={props => <ResetPassword {...props}
                blocking={this.state.blocking}
                toggleBlocking={(e) => this.toggleBlocking(e)}
                blockingMessage={this.state.blockingMessage}
                setBlockUIMessage={(message) => this.setBlockUIMessage(message)}
                logout = {() => {this.logout()}}
              />} />

              <PrivateRoute path="/table-migration" render={props => <TableMigration {...props}
                setInboundConnection={(connection) => this.setInboundConnections(connection)}
                setOutboundConnection={(connection) => this.setOutboundConnections(connection)}
                setChoice={(connectionName) => this.setChoice(connectionName)}
                inbound_connections={this.state.inbound_connections}
                outbound_connections={this.state.outbound_connections}
                choice={this.state.choice}
                selectedSystem={this.state.selectedSystem}
                setSelectedSystem={(system) => { this.setSelectedSystem(system) }}
                deleteConnection = {(caseParam, connectionName) => {this.deleteConnection(caseParam, connectionName)}}
                blocking={this.state.blocking}
                toggleBlocking={(e) => this.toggleBlocking(e)}
                blockingMessage={this.state.blockingMessage}
                setBlockUIMessage={(message) => this.setBlockUIMessage(message)}
                logData={this.state.logData}
                log={(logData) => { this.log(logData) }}
                logout = {() => {this.logout()}}
              />} />

              <PrivateRoute path="/object-migration" render={props => <ObjectMigration {...props}
                setInboundConnection={(connection) => this.setInboundConnections(connection)}
                setOutboundConnection={(connection) => this.setOutboundConnections(connection)}
                setChoice={(connectionName) => this.setChoice(connectionName)}
                inbound_connections={this.state.inbound_connections}
                outbound_connections={this.state.outbound_connections}
                choice={this.state.choice}
                selectedSystem={this.state.selectedSystem}
                setSelectedSystem={(system) => { this.setSelectedSystem(system) }}
                deleteConnection = {(caseParam, connectionName) => {this.deleteConnection(caseParam, connectionName)}}
                blocking={this.state.blocking}
                toggleBlocking={(e) => this.toggleBlocking(e)}
                blockingMessage={this.state.blockingMessage}
                setBlockUIMessage={(message) => this.setBlockUIMessage(message)}
                logData={this.state.logData}
                log={(logData) => { this.log(logData) }}
                logout = {() => {this.logout()}}
              />} />

              <PrivateRoute path="/view-migration" render={props => <ViewMigration {...props}
                setInboundConnection={(connection) => this.setInboundConnections(connection)}
                setOutboundConnection={(connection) => this.setOutboundConnections(connection)}
                inbound_connections={this.state.inbound_connections}
                outbound_connections={this.state.outbound_connections}
                setChoice={(choice) => this.setChoice(choice)}
                choice={this.state.choice}
                selectedSystem={this.state.selectedSystem}
                setSelectedSystem={(system) => { this.setSelectedSystem(system) }}
                deleteConnection = {(caseParam, connectionName) => {this.deleteConnection(caseParam, connectionName)}}
                blocking={this.state.blocking}
                toggleBlocking={(e = null) => this.toggleBlocking(e)}
                blockingMessage={this.state.blockingMessage}
                setBlockUIMessage={(message) => this.setBlockUIMessage(message)}
                logData={this.state.logData}
                log={(logData) => { this.log(logData) }}
                logout = {() => {this.logout()}}
              />} />

              <PrivateRoute path="/data-migration" render={props => <DataMigration {...props}
                setInboundConnection={(connection) => this.setInboundConnections(connection)}
                setOutboundConnection={(connection) => this.setOutboundConnections(connection)}
                inbound_connections={this.state.inbound_connections}
                outbound_connections={this.state.outbound_connections}
                setChoice={(choice) => this.setChoice(choice)}
                choice={this.state.choice}
                selectedSystem={this.state.selectedSystem}
                setSelectedSystem={(system) => { this.setSelectedSystem(system) }}
                deleteConnection = {(caseParam, connectionName) => {this.deleteConnection(caseParam, connectionName)}}
                blocking={this.state.blocking}
                toggleBlocking={(e = null) => this.toggleBlocking(e)}
                blockingMessage={this.state.blockingMessage}
                setBlockUIMessage={(message) => this.setBlockUIMessage(message)}
                logData={this.state.logData}
                log={(logData) => { this.log(logData) }}
                logout = {() => {this.logout()}}
              />} />

              <PrivateRoute path="/landing-page" render={props => <LandingPage {...props}
                setChoice={(choice) => this.setChoice(choice)}
                choice={this.state.choice}
                setOutboundConnection={(connection) => this.setOutboundConnections(connection)}
                inbound_connections={this.state.inbound_connections}
                setInboundConnection={(connection) => this.setInboundConnections(connection)}
                outbound_connections={this.state.outbound_connections}
                selectedSystem={this.state.selectedSystem}
                setSelectedSystem={(system) => { this.setSelectedSystem(system) }}
                blocking={this.state.blocking}
                toggleBlocking={(e = null) => this.toggleBlocking(e)}
                logout = {() => {this.logout()}}
              />} />


            </Switch>
          </React.Suspense>
        </div>
      </Router>
    );
  }
}

export default App;