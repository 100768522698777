export const base_url_for_api = "https://dfast.aurasuite.ai/";
export const bw_obj = {
    INFOOBJ : "INFO OBJECT",
    DSOOBJ : "DSO",
    OPENHUB : "OPENHUB"
    };

export const bw_obj_outbound_schema = {
    INFOOBJ : "INFOBJECT_SCHEMA",
    DSOOBJ : "DSO_SCHEMA",
    OPENHUB : "OPENHUB_SCHEMA"
}

export const bw_obj_keys = {
    INFOOBJ : "table_names",
    DSOOBJ : "table_names",
    OPENHUB : "table_names"
}

export const Messages ={
    ERROR : "An error occurred, please try again.",
    MIG_SUCCESS : "Migrated Successfully!.",
    LOAD_SUCCESS : "Loaded Successfully!.",
} 

export const BlockUI_Messages = {
    Default : "",
    Connect : "Please Wait, We are Connecting...",
    Table_Load: "Please Wait, Tables are Loading...",
    View_Load: "Please Wait, Views are Loading...",
    Data_Load: "Please Wait, Data is Loading...",
    Object_Load:  "Please Wait, Objects are Loading...",

    Table_Mig: "Please Wait, Tables are Migrating...",
    View_Mig: "Please Wait, Views are Migrating...",
    Data_Mig: "Please Wait, Data is Migrating...",
    Object_Mig:  "Please Wait, Objects are Migrating...",
}
